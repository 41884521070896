import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import routes from './routes.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
// --------------------------------
// Cookies
// --------------------------------
import VueCookies from 'vue-cookies'
import cookies from "./modules/cookies/cookieController";

// --------------------------------
// Lazy load image
// --------------------------------
import lazyLoad from './modules/lazyload';
Vue.directive('lazyload', lazyLoad);

// --------------------------------
// Google Analytics
// --------------------------------
// const VueAnalytics = require('vue-analytics');


Vue.config.productionTip = false
// --------------------------------
// Vue Router
// --------------------------------
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history', // web
  // base: process.env.BASE_URL,
  // mode: 'hash', // SI ES MOVIL
  //mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  routes
})

// printSiteMap(routes, 'https://examenselectivitat.cat');


router.beforeEach((to, from, next) => {
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  next();
});


// --------------------------------
// Vue Meta
// --------------------------------
Vue.use(VueMeta, {
  // keyName: 'metaInfo',
  // attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  // tagIDKeyName: 'vmid',
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// --------------------------------
// Axios
// --------------------------------
Vue.use(VueAxios, axios)
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_URI


// --------------------------------
// Google analytics
// --------------------------------

// Vue.use(VueAnalytics, {
//   id: 'G-46KND078WR',
//   router
// })



Vue.component('my-component', {})


Vue.use(VueCookies);

Vue.$cookies.config('7d', '', '', true)

// Add global premium configuration
Vue.prototype.$premium = {
  offer: true, // Whether premium is currently on offer
  regularPrice: 14.99,
  discountPercentage: 67, // 67% discount
  get currentPrice() {
    return this.offer ?
      +(this.regularPrice * (1 - this.discountPercentage / 100)).toFixed(2) :
      this.regularPrice;
  }
}

const app = new Vue({
  data() {
    return {
      currentUser: null,
      token: null,
      cart: 0,
      showCaptcha: false
    }
  },
  vuetify,
  router,
  template: '',
  render: h => h(App),
  async mounted() {
    document.dispatchEvent(new Event('x-app-rendered'));
    await cookies.user.setInitialValues();
    cookies.token.setInitialValues();
    this.currentUser = cookies.user;
    this.token = cookies.token;
    const cartCookie = Vue.$cookies.get("cart")
    this.cart = 0;
    if (cartCookie && Object.keys(cartCookie)) {
      Object.values(cartCookie).forEach(v => this.cart += v)
    } else {
      Vue.$cookies.set("cart", {})
    }
    await cookies.user.setValues(cookies.user);
  },
  computed: {

  }
}).$mount('#app')


// function printSiteMap(routes, pre) {
//   routes.forEach((route) => {
//     const path = `${pre}${route.path}`;
//
//     if (route.path !== '*') {
//       console.log(path)
//     }
//
//     if (route.children) {
//       printSiteMap(route.children, `${path}/`);
//     }
//
//   }, []);
//
// }

