<template>
  <div v-if="user && user.value"
       class="d-flex align-center">
    <div class="d-flex flex-column mr-4">
      <span class="caption" style="color: #505a71; line-height: 10px">Hola</span>
      <span class="tit" style="color: #505a71; font-size: 14px">{{ user.value.user_name }}</span>
    </div>
    <v-menu
      v-model="showMenu"
      offset-y
      nudge-bottom="5"
      :close-on-content-click="false"
      min-width="280"
      left
      content-class="shadow-small"
      style="width: 280px"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-hover class="transition-linear-out-slow-in" v-slot:default="{ hover }">
          <v-sheet
            v-on="on"
            color="transparent" style="cursor: pointer">
              <v-avatar
                v-if="user && user.value"
                :size="$vuetify.breakpoint.smAndDown ? 48 : 40"
                :color="hover ? 'amber' : 'primary'"
                style="cursor: pointer"
                class="white--text  text-uppercase subtitle-2 tit"
              >
                <span>{{ user.value.user_name.substring(0, 1) }}</span>
              </v-avatar>
          </v-sheet>
        </v-hover>
      </template>

      <v-card>


        <div class="d-flex justify-center py-2 pl-5 pr-3">
          <v-icon size="20"></v-icon>
          <span class="text-center ml-4" style="color: #5e6c84; font-size: 15px; flex: 1">Compte</span>
          <v-icon @click="showMenu=false" size="20">{{mdiClose}}</v-icon>
        </div>

        <v-divider></v-divider>


        <div class="d-flex px-5 py-2">
          <v-avatar
            v-if="user && user.value"
            :size="$vuetify.breakpoint.smAndDown ? 48 : 40"
            color="primary"
            class="white--text  text-uppercase subtitle-2 tit"
          >
            <span>{{ user.value.user_name.substring(0, 1) }}</span>
          </v-avatar>

          <div class="d-flex flex-column">
            <span class="ml-2 font-weight-medium" style="color: #505a71; font-size: 14px">{{
                user.value.user_name
              }}</span>
            <span class="ml-2" style="color: #B3BAC5; font-size: 12px">{{
                user.value.user_email
              }}</span>
          </div>
        </div>

        <v-divider></v-divider>




        <v-list class="body-2 px-2 py-1 profile-menu-list" @click="showMenu=false">
          <router-link
            v-if="$root.currentUser && $root.currentUser.isAdmin()"
            to="/admin">
            <v-list-item>
              <span style="color: #ffbb00 !important">Administrador</span>
            </v-list-item>
          </router-link>

          <router-link to="/perfil">
            <v-list-item>
              <span>El meu perfil</span>
            </v-list-item>
          </router-link>

          <router-link to="/perfil/configuracio">
            <v-list-item>
              <span>Configuració</span>
            </v-list-item>
          </router-link>

          <v-divider></v-divider>
          <v-list-item
            @click="signOut"
            class="mt-1">
            <span>Tancar sessió</span>
          </v-list-item>
        </v-list>
      </v-card>

    </v-menu>
  </div>

</template>

<script>
import {mdiClose} from '/src/assets/mdi.json'

export default {
  data() {
    return {
      mdiClose: mdiClose,
      showMenu: false
    }
  },
  computed: {
    token() {
      return this.$root.token
    },
    user() {
      return this.$root.currentUser
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
    }
  },
  methods: {
    goTo: function (row) {
      this.$router.push({path: `${row}`})
    },
    goToAuth() {
      this.$store.state.step = 1
      this.$store.commit('toggleAuth')
    },
    goToSignIn() {
      this.$router.push({path: '/login'})
    },
    async signOut() {
      await this.axios.delete('/logout', {
        headers: {
          'Authorization': this.token.value
        }
      });
      this.user.remove();
      this.token.remove();
      if (this.$route.path !== '/') {
        await this.$router.push({path: '/'})
      }
      window.location.reload();
    }
  }
}
</script>

<style>

.profile-menu-list .v-list-item {
  min-height: 38px !important;
}

.profile-menu-list .v-list-item span {
  color: #172b4d !important;
  font-size: 14px !important;
}

.custom-placeholer-color input::placeholder {
  color: white !important;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
  opacity: 1;
  letter-spacing: 0.5px;
  padding-left: 0px !important;
}

.magny .v-icon.v-icon {
  color: white !important;
  font-size: 18px !important;
  margin-left: 25px !important;
}

.custom-placeholer-color input {
  font-family: Rubik, sans-serif !important;
  opacity: 1;
  letter-spacing: 0.5px;
  color: white !important;
  border: none !important;
}

.custom-placeholer-color .v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
